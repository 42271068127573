<template>
    <div>
        <card-header title="New Event" icon="fa-plus" :subheader="trip.title + ': Day ' + day.number + ': ' + dayDate"/>

        <card-body>
            <event-form :event="newEvent"/>
        </card-body>

        <card-footer class="pt-3 pb-3 has-text-right">
            <b-button type="is-primary" size="is-small" :disabled="!newEvent.title || !newEvent.location || !newEvent.start_date || !newEvent.start_time || !newEvent.start_tz || !newEvent.end_date || !newEvent.end_time || !newEvent.end_tz" @click="submit"><i class="fas fa-plus mr-3"></i>New Event</b-button>
        </card-footer>



    </div>
</template>

<script>
    import CardHeader from '../../TIER/components/CardHeader';
    import CardContainer from '../../TIER/components/CardContainer';

    import {client as http} from '../../http_client';
    import CardFooter from "../../TIER/components/CardFooter";
    import CardList from "../../TIER/components/CardList";
    import SubheaderListItem from "../../TIER/components/SubheaderListItem";

    import CardBody from "../../TIER/components/CardBody";
    import ContainerListItem from "../../TIER/components/ContainerListItem";
    import moment from 'moment-timezone';
    import EventForm from "./EventForm";
    import async from "async";

    export default {
        name: "NewEvent",
        props: ['card', 'props'],
        components: {EventForm, ContainerListItem, CardBody, SubheaderListItem, CardList, CardFooter, CardContainer, CardHeader},
        data: function() {
            return {
                newEvent: {
                    type: 'event',
                    start_date: null,
                    start_time: null,
                    end_date: null,
                    end_time: null,
                    start_tz: '',
                    end_tz: '',
                    task: {},
                    task_complete: 1
                },
                zones: moment.tz.names(),
                day: {},
                trip: {}
            };
        },
        computed: {
            startTzResults() {
                return this.zones.filter((option) => {
                    return option
                        .toString()
                        .toLowerCase()
                        .indexOf(this.newEvent.start_tz.toLowerCase()) >= 0;
                });
            },
            endTzResults() {
                return this.zones.filter((option) => {
                    return option
                        .toString()
                        .toLowerCase()
                        .indexOf(this.newEvent.end_tz.toLowerCase()) >= 0;
                });
            },
            dayDate: function() {
                return moment(this.day.day_on).format('dddd, MMMM Do, YYYY');
            }
        },
        methods: {
            loadDay() {
                this.$emit('loading', true);
                async.series([
                    // Get day
                    (cb) => {
                        http.get('/api/days/' + this.props.dayId).then(response => {
                            this.newEvent.start_date = response.data.day_on;
                            this.newEvent.end_date = response.data.day_on;
                            this.day = response.data;
                            cb();
                        });
                    },
                    // Get trip
                    (cb) => {
                        http.get('/api/trips/' + this.day.trip_id).then(response => {
                            this.trip = response.data;
                            this.newEvent.start_tz = this.trip.trip_tz;
                            this.newEvent.end_tz = this.trip.trip_tz;
                            cb();
                        });
                    }
                ], err => {
                    this.$emit('loading', false);
                });
            },
            submit() {
                this.$emit('loading', true);
                http.post('/api/trips/' + this.props.tripId + '/days/' + this.props.dayId, this.newEvent, {force: true}).then(response => {
                    this.$reloadCard('days', {tripId: this.props.tripId});
                    this.$reloadCard('day', {dayId: this.props.dayId});
                    this.$reloadCard('trip', {tripId: this.props.tripId});
                    this.$reloadCard('trips');
                    this.$saveAlert(false);
                    this.$closeCard(this.card);
                }).catch(err => {
                    console.log('An error occurred.');
                    this.$emit('loading', false);
                });
            },
            selectTZ(option) {
                // console.log('Selected', moment().tz(option).format('MMMM Do YYYY, h:mm:ss a Z'));
            }
        },
        mounted() {
            this.newEvent.day_id = this.props.dayId;
            this.$saveAlert(true, 'Are you done creating this new event? Unsaved changes will be lost.');
            this.loadDay();
        }
    };
</script>
